import React, { useState } from "react";
import { useStaticQuery, graphql } from 'gatsby'

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import TitleProduct from "../../objects/TitleProduct"
import BannerProduct from "../../components/BannerProduct"
import CardProduct from "../../components/CardProduct"
import CardsSection from "../../components/CardsSection"
import FreeShippingSection from "../../components/FreeShippingSection"
import ModalLineInfo from "../../components/ModalLineInfo"
import { cards, infos } from "./content"

const Ombrelones = () => {

   const imgContent = useStaticQuery (
      graphql`
         query {
            imgBanner: file(relativePath: { eq: "banner-ombrelone.jpg" }) {
               childImageSharp {
                  fluid(maxHeight: 515) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            imgCards: allFile(
                  sort: {order: ASC, fields: 
                     childImageSharp___fluid___originalName},
                  filter: {relativeDirectory: {eq: "ombrelones"}}) {
               edges {
                 node {
                   childImageSharp {
                     fluid(maxWidth: 576) {
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
               }
            }
         }
      `
   )

   const imagesCards = imgContent.imgCards.edges

   const [showModal, setShowModal] = useState(false)
   
   const handleOnLoad = () => {
      if (typeof window !== `undefined`) {
         const $html = document.querySelector("html")
         return { $html }
      }
   }

   const handleOpenModal = event => {
      event.preventDefault();
      setShowModal(true)
      const { $html } = handleOnLoad()
      $html.style.overflow = "hidden"
   }

   const handleCloseModal = event => {
      event.preventDefault();
      setShowModal(false)
      const { $html } = handleOnLoad()
      $html.style.overflow = "auto"
   }

   return (
      <Layout>
         <SEO title="Ombrelones"/>
         <Header title=""/>
         <TitleProduct onLoad={handleOnLoad}
            title="Ombrelones"
            label="Características"
            onClick={event => handleOpenModal(event)}
         />
         <ModalLineInfo 
            isShow={showModal} 
            onClick={event => handleCloseModal(event)}
            infos={infos} />
         <BannerProduct 
            imgFluid={imgContent.imgBanner.childImageSharp.fluid}
         />
         <CardsSection>
            {imagesCards.map((img, i) => {
               return (
                  <CardProduct 
                     key={i}
                     target={cards[i].target}
                     imgFluid={img.node.childImageSharp.fluid}
                     alt={cards[i].alt}
                     title={cards[i].title}
                     description={cards[i].description}
                  />
               )
            })}
         </CardsSection>
         <FreeShippingSection />
         <Footer />
      </Layout>
   )
}

export default Ombrelones;