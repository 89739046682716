const cards = [
   {
      id: "img01",
      target: "",
      alt: "Ombrelone Quadrado",
      title: "Ombrelone Quadrado",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Base em fibra para Ombrelone em fibra",
      title: "Base em fibra para Ombrelone",
      description: "Em fibra",
   },
   {
      id: "img03",
      target: "",
      alt: "Base maior para Ombrelone em fibra",
      title: "Base maior para Ombrelone",
      description: "Em fibra",
   },
   {
      id: "img04",
      target: "",
      alt: "Base grande para Ombrelone",
      title: "Base grande para Ombrelone",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Base plástica para Ombrelone cor preta",
      title: "Base plástica para Ombrelone",
      description: "Cor preta",
   },
   {
      id: "img06",
      target: "",
      alt: "Detalhe de Ombrelone com haste central fixa detalhe armação",
      title: "Detalhe de Ombrelone com haste central fixa",
      description: "Detalhe da armação",
   },
   {
      id: "img07",
      target: "",
      alt: "Guarda Sol 400m",
      title: "Guarda Sol 400m",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Ombrelone Central Quadrado",
      title: "Ombrelone Central Quadrado",
      description: "",
   },
   {
      id: "img09",
      target: "",
      alt: "Ombrelone Central Redondo",
      title: "Ombrelone Central Redondo",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Ombrelone Central Viva Vida",
      title: "Ombrelone Central Viva Vida",
      description: "",
   },
   {
      id: "img11",
      target: "",
      alt: "Ombrelone Lateral",
      title: "Ombrelone Lateral",
      description: "",
   },
   {
      id: "img12",
      target: "",
      alt: "Principal Ombrelone Premium com Floreira",
      title: "Principal Ombrelone Premium com Floreira",
      description: "",
   },
]

const infos = [
   {
      title: "Características",
      description: [
         {
            paragraph: [
               "MADEIRA: OMBRELONE MADEIRA EUCALIPTO",
               "TRATAMENTO: Impregnante",
               "TECIDO: Bagum Jari 0,30, composto por 84% de PVC e 16% de tela de Poliéster",
               "PERSONALIZAÇÃO: Personalize seu OMBRELONE CENTRAL temos a opção de impressão da logomarca no tecido do ombrelone (hóteis, restaurantes, cervejarias,etc).",
               "GLOBO E CARAMBOLA: Madeira",
               "DIÁMETRO DO MASTRO: 41 mm (O mastro é inteiro proporcionando assim maior durabilidade ao ombrelone)"
            ]
         }
      ]
   },
   {
      title: "Ombrelone Redondo",
      description: [
         {
            paragraph: [
               "REDONDO | 2.0 m",
               "Base indicada: A1",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.82 m (aproximadamente)"      
            ]
         },
         {
            paragraph: [
               "REDONDO | 2.5 m",
               "Base indicada: A1",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.82 m (aproximadamente)"   
            ]
         },
         {  paragraph: [
               "REDONDO | 3.0 m",
               "Base indicada: A1",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.88 m (aproximadamente)"
            ]
         },
         {  
            paragraph: [
               "REDONDO | 2.0 m",
               "Base indicada: A2",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.90 m (aproximadamente)"
            ]
         },
         {  paragraph: [
               "REDONDO | 4.0 m",
               "Base indicada: A5",
               "Diâmetro da haste: 60 mm",
               "Altura livre: 2.10 m (aproximadamente)"
            ]
         },
         {  paragraph: [
               "REDONDO | 5.0 m",
               "Base indicada: A5",
               "Diâmetro da haste: 60 mm",
               "Altura livre: 2.15 m (aproximadamente)"
            ]
         }
      ]
   },
   {
      title: "Ombrelone Quadrado",
      description: [
         {
            paragraph: [
               "QUADRADADO | 2.0X2.0 m",
               "Base indicada: A1",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.88 m (aproximadamente)"
            ]
         },
         {
            paragraph: [
               "QUADRADADO | 2.5X2.5 m",
               "Base indicada: A1",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.90 m (aproximadamente)"
            ]
         },
         {
            paragraph: [
               "QUADRADADO | 3.0X3.0 m",
               "Base indicada: A3",
               "Diâmetro da haste: 41 mm",
               "Altura livre: 1.90 m (aproximadamente)"
            ]
         },
         {
            paragraph: [
               "QUADRADADO | 3.5X3.5 m",
               "Base indicada: A5",
               "Diâmetro da haste: 60 mm",
               "Altura livre: 2.20 m (aproximadamente)"
            ]
         },
         {
            paragraph: [
               "QUADRADADO | 4.0X4.0 m",
               "Base indicada: A5",
               "Diâmetro da haste: 60 mm",
               "Altura livre: 2.20 m (aproximadamente)"
            ]
         }
      ]
   }
]

export { cards, infos }