import React from "react";

import { BoxModal, 
         BoxContent,
         ColumnContent,
         CloseButton,
         Title,
         Description,
         Line } from "./styles"

const ModalLineInfo = ({ isShow, onClick, infos }) => {

   return (
         <BoxModal className={isShow ? "-visible" : ""}>
            <CloseButton onClick={onClick}>
               Fechar
            </CloseButton>
            <BoxContent>
               {infos.map((info, i) => {
                  return (
                     <ColumnContent>
                        <Title key={i}>{info.title}</Title>
                        {info.description.map(item => (
                           <Description>
                              {item.paragraph.map(line => (
                                 <Line>{line}</Line>
                              ))}
                           </Description>
                        ))}
                     </ColumnContent>
                  )
               })}
            </BoxContent>
         </BoxModal>
   )
}

export default ModalLineInfo